import axios from "axios";
import urls from "./baseUrl.js";
import store from "utilities/storage.js";

let token = store.retrieve("ShRSessToken");

const CreateAccount = {
  async linkedIn(code) {
    try {
      const data = await axios.post(urls.base_url + "account/linkedin", {
        code: code,
      });
      return data.data;
    } catch (error) {
      return error;
    }
  },
  async google(access_token) {
    try {
      const data = await axios.post(urls.base_url + "account/google", {
        token: access_token,
      });
      return data.data;
    } catch (error) {
      throw error;
    }
  },
  async form(formData) {
    try {
      const data = await axios.post(urls.base_url + "account", formData);
      return data.data;
    } catch (error) {
      return error;
    }
  },
  async createCustomAccount(neighborhood_id, formData) {
    try {
      const data = await axios.post(
        `${urls.base_url}account/neighborhood/${neighborhood_id}`,
        formData
      );
      return data.data;
    } catch (error) {
      return error;
    }
  },
  async getOnboardingData(neighborhoodOnboardingName) {
    try {
      const data = await axios.get(
        `${urls.base_url}account/neighborhood/${neighborhoodOnboardingName}/onboarding-metadata`
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },
  async submitQuestionnaire({ neighborhood_id, questionaire_id, response }) {
    try {
      const data = await axios.post(
        `${urls.base_url}account/neighborhood/${neighborhood_id}/questionaire/${questionaire_id}`,
        { response },
        {
          headers: {
            Authorization: `Bearer: ${store.retrieve("ShRSessToken")}`,
          },
        }
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },
  async confirmRole(formData) {
    try {
      const data = await axios.patch(urls.base_url + "account/role", formData);
      return data.data;
    } catch (error) {
      return error;
    }
  },
  async verifyEmailExists(email) {
    try {
      const data = await axios.post(urls.base_url + "account/verify/email", {
        email: email,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getInviteInfo(invite_id) {
    try {
      const data = await axios.get(
        urls.base_url + "account/invite/" + invite_id
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async requestPasswordResetLink(email) {
    try {
      const data = await axios.post(
        urls.base_url + "account/notify/password-reset",
        { email: email }
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async resetPassword({ password, token }) {
    try {
      const data = await axios.post(
        urls.base_url + "account/reset-password/" + token,
        { password: password }
      );
      return data.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  },
  async getProfilePicture(crib_id) {
    const url = `${urls.base_url}crib/profilepic/${crib_id}/${token}`;
    try {
      const resp = await axios.get(url, {
        responseType: "arraybuffer",
      });
      if (resp.data === "Image not found") {
        return "Image not found";
      }
      return Buffer.from(resp.data, "binary").toString("base64");
    } catch (error) {
      throw error;
    }
  },
  async getProfilePictureURL(crib_id) {
    const url = `${urls.base_url}crib/profilepic/${crib_id}/${token}`;
    try {
      const resp = await axios.get(url);
      if (resp.data === "Image not found") {
        return "Image not found";
      }
      return resp.data;
    } catch (error) {
      throw error;
    }
  },
};

export default CreateAccount;

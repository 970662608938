import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { appLogo } from "config";
import { useGoogleLogin } from "@react-oauth/google";
import { SnackbarContext } from "contextStore/SnackbarContext";
import { LinkedInButton } from "components/auth/LinkedInButton";
import { ReactComponent as CreateAccountRoot } from "../assets/Icons/CreateAccountRoot.svg";
import { ReactComponent as VisibilityOffOutlined } from "../assets/Icons/visibility_24px_outlined.svg";
import { ReactComponent as VisibilityOutlined } from "../assets/Icons/visibility_off_24px_outlined.svg";
import { ReactComponent as Line } from "../assets/Icons/Line.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AuthFormErrorText from "../components/AuthFormErrorText.js";
import CreateAccountREQ from "../api/Account.js";
import storeSession from "../utilities/session/sessionInitialIzer.js";
import isEmpty from "../utilities/isEmpty";
import FormFieldValidationTick from "components/FormFieldValidationTick";
import CityLabLogo from "assets/logos/CLP_logo.png";
import BtnLoader from "components/Loader/btn-loader";
import AppLoader from "components/Loader/loader";
import GoogleAuthButton from "../components/auth/GoogleButton/GoogleAuthButton";
import "../styles/pages/CreateAccount.css";

/**
 *  Signup form containing
 *  - basic form
 *  - google signup button
 *  - linkedIn signup button
 */

const Form = ({ setFormIsSubmited, customRoute, neighborhood_id }) => {
  const { triggerAlert } = useContext(SnackbarContext);

  const defaultFormValidStatus = {
    Firstname: undefined,
    Lastname: undefined,
    Email: undefined,
  };

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formFieldIsValid, setFormFieldIsValid] = useState(
    defaultFormValidStatus
  );
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [formErrorText, setFormErrorText] = useState("");
  const [sessionData, setSessionData] = useState(null);

  /**
   * Check if name is valid
   *  - name lenght greater than 1 text
   *  - name does not contain number and spaces
   * @param {String}: name
   * @returns {boolean}
   */
  const isValidName = (name) => {
    if (name && name.length > 1) {
      return true;
    }
    return false;
  };

  /**
   * Check if email is valid
   * @param {String}: email
   * @returns {boolean}: true/false
   */
  const isValidEmailFormat = (email) => {
    let format =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email && email.match(format)) {
      return true;
    }
    return false;
  };

  /**
   * Validate field by type
   * @param {String} fieldname
   * @param {String} value
   */
  const validateFormField = (fieldname, value) => {
    switch (fieldname) {
      case "firstname":
        setFormFieldIsValid({
          ...formFieldIsValid,
          Firstname: isValidName(value),
        });
        break;
      case "lastname":
        setFormFieldIsValid({
          ...formFieldIsValid,
          Lastname: isValidName(value),
        });
        break;
      case "email":
        setFormFieldIsValid({
          ...formFieldIsValid,
          Email: isValidEmailFormat(value),
        });
        break;
      default:
        break;
    }
  };

  /**
   * Create user account
   * - send user info to server
   *
   * @param {String} fieldname
   * @param {String} value
   */

  const handleFormSubmit = async (FormFieldIsValid) => {
    const { Firstname, Lastname, Email } = FormFieldIsValid;
    const formIsValidated = Firstname && Lastname && Email;
    if (formIsValidated) {
      setFormSubmitInProgress(true);
      const formData = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
      };

      const data = customRoute
        ? await CreateAccountREQ.createCustomAccount(neighborhood_id, formData)
        : await CreateAccountREQ.form(formData);
      if (data.status === "success") {
        storeSession(data.data);
        setFormSubmitInProgress(false);
        setFormIsSubmited(true);
      } else {
        if (data.response && data.response.status === 400) {
          if (data.response.data.error === "duplicate email") {
            setFormErrorText(
              "An account with this email already exists, sign in instead?"
            );
          } else {
            setFormErrorText(data.response.data.error);
          }
        } else if (data.response && data.response.status === 500) {
          setFormErrorText("Server error, please try again");
        } else {
          setFormErrorText("Please check your connection");
        }
      }
      setFormSubmitInProgress(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(sessionData)) {
      // identifyUserWithLou(sessionData, LOU);
    }
  }, [sessionData]);

  /**
   * Update field value
   * validate field
   *
   * @param {String} fieldname
   * @param {String} value
   */

  const handleChange = async (fieldname, value) => {
    switch (fieldname) {
      case "firstname":
        setFirstname(value);
        break;
      case "lastname":
        setLastname(value);
        break;
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
    validateFormField(fieldname, value);
    if (fieldname === "email" && formFieldIsValid.Email) {
      const req = await CreateAccountREQ.verifyEmailExists(value);
      if (req.data && req.data.email_exists) {
        setFormFieldIsValid({ ...formFieldIsValid, Email: false });
        setFormErrorText(
          "An account with this email already exists, sign in instead"
        );
      }
    }
  };

  const googleSigninMutation = useMutation({
    mutationFn: CreateAccountREQ.google,
    onError: (error) => {
      triggerAlert({
        open: true,
        message: error?.response?.data?.error
          ? error?.response?.data?.error
          : "There was an error, please try again later",
        type: "error",
      });
    },
    onSuccess: (response) => {
      if (response.status === "success") {
        storeSession(response.data);
        setSessionData(response.data);
        setFormIsSubmited(true);
      } else {
        triggerAlert({
          open: true,
          message: "Something went wrong, please try again later",
          type: "error",
        });
      }
    },
  });

  /**
   * handleGoogleSignIn
   *  - Send user's ID to server to complete google sign-in
   * @param {Object} response
   */

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: (codeResponse) => {
      googleSigninMutation.mutate(codeResponse.access_token);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleLinkedInSigninFailure = (response) => {
    setFormErrorText(response.errorMessage);
  };

  const handleLinkedInSigninSuccess = async (response) => {
    const data = await CreateAccountREQ.linkedIn(response.code);
    if (data.status === "success") {
      storeSession(data.data);
      setSessionData(data.data);
      setFormSubmitInProgress(false);
      setFormIsSubmited(true);
      setFormIsSubmited(true);
    } else {
      if (data.response && data.response.status === 400) {
        if (data.response.data.error === "duplicate email") {
          setFormErrorText(
            "An account with this email already exists, sign in instead?"
          );
        } else {
          setFormErrorText(data.response.data.error);
        }
      } else if (data.response && data.response.status === 500) {
        setFormErrorText("Server error, please try again");
      } else {
        setFormErrorText("Please check your connection");
      }
    }
  };

  return (
    <div className="FormWrapper">
      <div className="Form">
        <div className="FormInputFieldsWrapper">
          <AuthFormErrorText
            formErrorText={formErrorText}
            setFormErrorText={setFormErrorText}
          />
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={firstname}
              variant="outlined"
              name="firstname"
              margin="dense"
              label="First name"
              onChange={(e) => handleChange("firstname", e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Firstname} />
          </div>
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={lastname}
              margin="dense"
              variant="outlined"
              name="Last name"
              label="Last name"
              onChange={(e) => handleChange("lastname", e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Lastname} />
          </div>
          <div className="FieldWrapper">
            <TextField
              className="Field"
              value={email}
              variant="outlined"
              name="email"
              margin="dense"
              type="email"
              label="Email address"
              onChange={(e) => handleChange("email", e.target.value)}
            />
            <FormFieldValidationTick isValid={formFieldIsValid.Email} />
          </div>
          <div className="FieldWrapper">
            <CustomPassword password={password} setPassword={setPassword} />
          </div>
        </div>
        <div className="ActionWrapper">
          <span className="Action">
            <Button
              className="Submit"
              onClick={() => handleFormSubmit(formFieldIsValid)}
            >
              {formSubmitInProgress === true ? (
                <BtnLoader
                  border_bottom={"4px solid #FFFFFF"}
                  border_right={"4px solid #FFFFFF"}
                  border_left={"4px solid #838383"}
                  border_top={"4px solid #838383"}
                />
              ) : (
                "Create account"
              )}
            </Button>
            <span className="OrBreaker">
              <Line />
              Or
              <Line />
            </span>
            <GoogleAuthButton
              btnText="Sign up with Google"
              handleGoogleSignIn={handleGoogleSignIn}
              isLoading={googleSigninMutation.isLoading}
              className="GoogleBtn"
            />
            <LinkedInButton
              className="LinkednButton"
              text="Sign up with Linkedin"
              handleLinkedInSigninSuccess={handleLinkedInSigninSuccess}
              handleLinkedInSigninFailure={handleLinkedInSigninFailure}
            />
          </span>
          <span className="Privacy">
            <span>
              By creating an account, you agree to the Rialto
              <a className="Link" href="/terms">
                {" "}
                Terms of Use
              </a>{" "}
              &{" "}
              <a className="Link" href="/privacy">
                Privacy Policy
              </a>
            </span>
          </span>
          <span className="Signin">
            <Line />
            Already have an account?
            <Line />
          </span>
          <a href={customRoute ? `/${customRoute}/sign-in` : "/sign-in"}>
            <Button className="SigninButton">Sign in</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

const CustomPassword = ({ password, setPassword }) => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  return (
    <>
      <TextField
        className="Field"
        value={password}
        variant="outlined"
        margin="dense"
        name="password"
        label="Password"
        type={passwordIsVisible ? "text" : "password"}
        onChange={(e) => setPassword(e.target.value)}
      />
      {passwordIsVisible ? (
        <span className="VisibilityIconWrapper">
          <VisibilityOutlined
            onClick={() => setPasswordIsVisible(false)}
            className="VisibilityIcon"
          />
        </span>
      ) : (
        <span className="VisibilityIconWrapper">
          <VisibilityOffOutlined
            onClick={() => setPasswordIsVisible(true)}
            className="VisibilityIcon"
          />
        </span>
      )}
    </>
  );
};

export default function CreateAccount() {
  const [formSubmitted, setFormIsSubmited] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const navigate = useNavigate();
  const { customRoute } = useParams();

  useEffect(() => {
    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
    setScreenWidth(width);
  }, [screenWidth]);

  useEffect(() => {
    if (formSubmitted === true) {
      navigate(
        customRoute ? `/${customRoute}/sign-up/confirm` : "/sign-up/confirm"
      );
    }
  }, [formSubmitted, navigate]);

  const {
    isLoading,
    isFetching,
    refetch,
    data: onboardingData,
  } = useQuery({
    retry: 1,
    enabled: false,
    refetchOnWindowFocus: false,
    queryKey: "getOnboardingData",
    queryFn: () => CreateAccountREQ.getOnboardingData(customRoute),
  });

  useEffect(() => {
    if (customRoute) refetch();
  }, []);

  const { neighborhood_name, neighborhood_id } = onboardingData?.data ?? {};

  return (
    <>
      {isLoading || isFetching ? (
        <AppLoader />
      ) : (
        <div className="CreateAccountContainer">
          <div className="CreateAccount">
            <div className="CreateAccountBanner">
              <div className="BannerInfo">
                {customRoute ? (
                  <img
                    src={CityLabLogo}
                    className="custom_logo"
                    alt="CityLab Logo"
                  />
                ) : (
                  <img
                    src={appLogo.desktop}
                    className="RialtoLogo"
                    alt="Rialto Logo"
                  />
                )}
                <div className="CreateAccountContent">
                  <span className="Title">Welcome to Rialto!</span>
                  {customRoute ? (
                    <span className="Content">
                      Bringing together the {neighborhood_name} community.
                      Create your account to get started!
                    </span>
                  ) : (
                    <span className="Content">
                      Ready to Hustle Forward with your new business community?
                      Create your account to get started!
                    </span>
                  )}
                  {customRoute ? (
                    <div className="powered_by">
                      <p className="powered_by_text">Powered by</p>
                      <img
                        src={appLogo.desktop}
                        className="powered_by_logo"
                        alt="Rialto Logo"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <CreateAccountRoot className="CreateAccountRoot" />
            </div>
            <Form
              setFormIsSubmited={setFormIsSubmited}
              customRoute={customRoute}
              neighborhood_id={neighborhood_id}
            />
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useWindowSize } from "hooks/useWindowSize";
import { Skeleton } from "@material-ui/lab";
import api from "../../api/index";
import sessionInfo from "utilities/session/sessionInfo";
import Twitter from "assets/svgs/twitter";
import Instagram from "assets/svgs/instagram";
import LinkedinOutline from "assets/svgs/linkedInOutline";
import OfficeHoursIcon from "assets/svgs/officeHours";
import UserImageAvatar from "components/GlobalComponents/UserImageAvatar";
import "./CribBanner.css";

const cribSocials = [
  {
    icon: <LinkedinOutline />,
    name: "linkedin",
    protocol: "https://www.linkedin.com/in/",
  },
  {
    icon: <Twitter />,
    name: "twitter",
    protocol: "https://www.x.com/",
  },
  {
    icon: <Instagram />,
    name: "instagram",
    protocol: "https://www.instagram.com/",
  },
];

const cribMobileNavigation = (crib_id) => {
  return [
    // {
    //   icon: <OfficeHoursIcon />,
    //   path: `/crib/${crib_id}/officehours`,
    //   name: "Office Hours",
    // },
  ];
};

const cribDesktopNavigation = (crib_id) => {
  return [
    {
      path: `/crib/${crib_id}`,
      name: "Profile",
    },
    // {
    //   path: `/crib/${crib_id}/officehours`,
    //   name: "Office Hours",
    // },
  ];
};

const menus = (links, role) => {
  return links.map(({ icon, name, path }) => (
    <li key={name} className="crib-banner__navigation--item">
      {role === "BCO" ? (
        <>
          {icon}
          <NavLink
            to={path}
            className={({ isActive }) =>
              `crib-banner__navigation--link ${isActive && "active"} `
            }
          >
            {name}
          </NavLink>
        </>
      ) : (
        name === "Profile" && (
          <>
            {icon}
            <NavLink
              to={path}
              className={({ isActive }) =>
                `crib-banner__navigation--link ${isActive && "active"} `
              }
            >
              {name}
            </NavLink>
          </>
        )
      )}
    </li>
  ));
};

const getURLHost = (url) => {
  return new URL(`https://${url}`).host;
};

const CribBanner = () => {
  const { width } = useWindowSize();
  const { crib_id } = useParams();
  const session = sessionInfo();

  const [cribProfileData, setCribProfileData] = useState({});

  const { isLoading } = useQuery(
    "getCribProfileInfo",
    () => api.Crib.getCribProfile(crib_id),
    {
      onError: (error) => {
        console.log(error);
      },
      onSuccess: (response) => {
        if (response?.status === "success") {
          setCribProfileData(response?.data?.profile);
        }
      },
    }
  );

  return (
    <div className="crib-banner">
      <div className="crib-banner__container">
        <div className="crib-banner__box">
          {isLoading ? (
            <div className="crib-banner__user">
              <Skeleton
                variant="circle"
                animation="pulse"
                className="crib-banner__user--img"
              />
              <div className="crib-banner__user--info">
                <Skeleton
                  variant="rect"
                  animation="pulse"
                  style={{
                    height: "18px",
                    borderRadius: "4px",
                    width: "11em",
                  }}
                />
                <Skeleton
                  variant="rect"
                  animation="pulse"
                  style={{
                    margin: "10px 0",
                    height: "16px",
                    borderRadius: "4px",
                    width: "7em",
                  }}
                />
                <Skeleton
                  variant="rect"
                  animation="pulse"
                  style={{
                    height: "12px",
                    borderRadius: "4px",
                    width: "5em",
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="crib-banner__user">
              <UserImageAvatar
                s3_picture_url={cribProfileData?.profile_picture}
                Alt={`${cribProfileData?.crib_first_name} avatar`}
                className="crib-banner__user--img"
              />
              <div className="crib-banner__user--info">
                <h2 className="crib-banner__user--name">
                  {cribProfileData?.crib_first_name}{" "}
                  {cribProfileData?.crib_last_name}
                </h2>
                <p className="crib-banner__user--job">
                  {cribProfileData?.job_title}
                </p>
                <p className="crib-banner__company">
                  {cribProfileData?.workplace}
                </p>
              </div>
            </div>
          )}
          <div className="crib-banner__socials--container">
            {cribProfileData?.website_link && (
              <a
                href={`https://${cribProfileData?.website_link}`}
                className="crib-banner__website"
                target="_blank"
                rel="noreferrer"
              >
                {getURLHost(cribProfileData?.website_link)}
              </a>
            )}
            <div className="crib-banner__socials">
              {cribSocials?.map(({ name, icon, protocol }) => {
                if (cribProfileData?.[name]) {
                  return (
                    <a
                      key={name}
                      href={`${protocol}${cribProfileData?.[name]}`}
                      className="crib-banner__social--link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {icon}
                    </a>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="crib-banner__navigation">
          <ul className="crib-banner__navigation--list">
            {width <= 767
              ? menus(cribMobileNavigation(crib_id), session.role)
              : menus(cribDesktopNavigation(crib_id), session.role)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CribBanner;
